import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from '../components/Home';
import AboutUs from '../components/Pages/AboutUs/index';
import ContactUs from '../components/Pages/ContactUs/index';
import TourDetails from '../components/Pages/TourDetails';
import Tour from '../components/Pages/Tour';
import ExploreDestination from '../components/Pages/Package/index';
import ExploreTourByCategory from '../components/Pages/Package/ExploreTourByCategory';
import Dashboard from '../components/Dashboard';
import FlightTicket from '../components/Pages/FlightTicket';
import FlightBooking from '../components/Pages/FlightTicket/FlightBooking';
import Login from '../components/Login';
import SignUp from '../components/SignUp';
import InvoicePDF from '../components/Pages/Invoice';
import Success from '../components/Pages/Success';
import FailedPayment from '../components/Pages/Failed';
import PrivacyAndPolicy from '../components/Policies/PrivacyAndPolicy';
import RefundAndCancellation from '../components/Policies/RefundAndCancellation';
import TermAndConditions from '../components/Policies/TermAndConditions';
import Sidebar from '../components/Sidebar';
import UserProfile from '../Layout/UserProfile';
import Profile from '../components/Profile';
const Routing=()=>{


   

    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />}/>
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/tour-details' element={<TourDetails />} />
                    <Route path='/tour-list' element={<Tour />} />
                    <Route path='/explore-destination' element={<ExploreDestination />} />
                    <Route path='/best-deals' element={<ExploreTourByCategory />} />
                    <Route path='/mytrip' element={<Dashboard />} />
                    <Route path='/search-flight' element={<FlightTicket />} />
                    <Route path='/flightbooking' element={<FlightBooking />} />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/invoice' element={<InvoicePDF />} />
                    <Route path='/payment-success' element={<Success />} />
                    <Route path='/payment-failed' element={<FailedPayment />} />
                    <Route path='/privacy-policy' element={<PrivacyAndPolicy />} />
                    <Route path='/refund-cancellation-policy' element={<RefundAndCancellation />} />
                    <Route path='/term-conditions-policy' element={<TermAndConditions />} />
                    <Route path='/side' element={<Sidebar />} />
                    <Route path='/userprofile' element={<Profile />} />
                    <Route path='/bookedpackage' element={<Sidebar />} />
                    <Route path='/bookedairticket' element={<Sidebar />} />
                    <Route path='/wallet' element={<Sidebar />} />
                    {/* <Route path='/user-profile' element={<UserProfile />} /> */}
                </Routes>
            </BrowserRouter>
            {/* <RouterProvider router={routes}/> */}
        </>
    )

}
export default Routing;