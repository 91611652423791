
const url={
    'homePage':'/',
    'searchTour':'/tour-list',
    'viewTourDetails':'/tour-details',
    'searchFlight':'/search-flight',
    'flightbooking':'/flightbooking',
    'login':'/login',
    'signup':'/signup',
    'package':'/bookedpackage',
    'airTicket':'/bookedairticket',
    'userProfile':'/userprofile',
    'wallet':'/wallet',
    'rzpykey':'rzp_test_9LBqF0Me9nXUZu',
    'contactUs':'/contact-us'
}
export default url;