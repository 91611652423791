import React from "react";
import Profile from "../components/Profile"

const componentsMap = {
  Profile: Profile
};

const DynamicComponent = ({ componentName }) => {
  const ComponentToRender = componentsMap[componentName];

  if (!ComponentToRender) {
    return <div>Component not found</div>; // Handle invalid component names
  }

  return <ComponentToRender />;
};

export default DynamicComponent;
