import React, { useEffect } from 'react';
import TrendingTourSlider from './trendingTourSlider';
import ScrollToTop from './scrollToTop';
import TopDestinations from './TopDestinations';
import Destinations from './Destinations';
import TopDealsAndDiscounts from './TopDealsAndDiscounts';
import Details from './Details';
import PartnerSlider from './PartnerSlider';
import Newsletter from './Newsletter';
import Footer from './Footer';
import Header from './Header';
import Finder from './Finder';
import Display from './Display';
import HomeBanner from './HomeBanner';
import ServicesSlider from './ServicesSlider';
import ProductDisplay from './ProductDisplay';
import CounterArea from './Counter';
import FAQ from './FAQ';

const Home = () => {
   
  return (
    <div>
      
        <Header />
        <HomeBanner />
        <Finder />
        <Display />
        <TrendingTourSlider categoryID={4} />
        <TopDestinations />
        <ProductDisplay />
        <ServicesSlider />
        <CounterArea />
        <FAQ />
        {/* <TopDealsAndDiscounts /> */}
        <Details />
        {/* <PartnerSlider /> */}
        <Newsletter />
        <Footer />
        <ScrollToTop />

        {/* Sorry, no flights available for the selected route. */}
    </div>

  );
};

export default Home;
