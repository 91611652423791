const CommunicationHub={
brandName:"Doco Trip",
address:"201 2nd Floor, Ace Airosa Complex, Lucknow",
 confirmBooking:'9120861339',
 contactMail:'docotrip@gmail.com',
 supportMail:'support@docotrip.com',
 landLineNo:'+05224575101',
 whatsappNo:'9935775799',
 faceBookPageUrl:'https://www.facebook.com/Travel-Right-115728023216903/',
 instagramUrl:'https://www.instagram.com/travelright_lko/?gshid=YmMyMTA2M2Y',
 aboutUs:'We are a one-stop travel agency that will help you while visiting various places whether in a short time or long time as well as solo or in a group.We are a very experienced travel provider, and many travelers love to travel with us.',
 officeLocation:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14239.594686279943!2d80.9363514!3d26.843175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf66936bcfa5080e2!2sTRAVEL%20RIGHT!5e0!3m2!1sen!2sin!4v1659461396565!5m2!1sen!2sin',
 offliceLocationCoordinate:'https://maps.app.goo.gl/RjogfHeXzhdHcsQW7',
}
export default CommunicationHub;