import React from 'react';
import Header from '../../Header';
import CounterArea from '../../Counter';
import Review from '../../Review';
import Newshletter from '../../Newsletter';
import ScrollToTop from '../../scrollToTop';
import Footer from '../../Footer';
import DocoBanner from '../../../assets/img/tr-image/Images/Doco-banner-v1.png'
import { Link } from 'react-router-dom';
import UseResetScroll from '../../../Common/AutoScroll/UseResetScroll';
import bestService from '../../../assets/img/tr-image/icons/bestService.png';
import trustedPayment from '../../../assets/img/tr-image/icons/trustedPayment.png';
import topFacility from '../../../assets/img/tr-image/icons/topFacility.png';
import bestDeals from '../../../assets/img/tr-image/icons/bestDeals.png';
import FAQ from '../../FAQ';
const AboutUs = ()=>{
    UseResetScroll();
    return(
        <>
            <Header customizedHeader={1}/>
            <section id="about_us_top" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_us_left">
                                <h5>About us</h5>
                                <h2>From Dreams to Destinations – Travel Made Easy with Doco Trip.</h2>
                                <p>
                                    We are a one-stop travel agency dedicated to creating seamless travel experiences,
                                    whether you’re planning a short getaway or an extended adventure. 
                                    We cater to solo travelers and groups alike, offering personalized assistance to ensure every journey is memorable and worry-free.
                                    Our extensive experience and commitment to service make us a trusted choice,
                                    with many travelers returning to explore the world with us. Additionally,
                                    we provide comprehensive flight ticketing options to suit any travel plan.
                                </p>
                                <Link to="https://www.google.com/maps/dir//TRAVEL+RIGHT+201+2nd+Floor,+Ace+Airosa+Complex,+Oden+Cinema+Cantonment+Rd,+Odeon+Colony,+Lalbagh+Lucknow,+Uttar+Pradesh+226001/@26.843086,80.9363452,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x399bfd0ea9542bc7:0xf66936bcfa5080e2!2m2!1d80.9363452!2d26.843086?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D" target='_blank' className="btn btn_theme btn_md">Locate Us</Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_us_right">
                                <img src={DocoBanner} style={{borderRadius:'100px'}} alt="img" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- About Service Area --> */}
            <section id="about_service_offer" className="section_padding_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed text-center">
                                <img className='about-icon' src={bestService} alt="img" />
                                <h5><a href="#!">Best services</a></h5>
                                <p>At Doco Trip, we go beyond booking; we’re dedicated to crafting journeys with care and attention. From start to finish, our experienced team is here to provide seamless, personalized service, making every trip memorable and stress-free.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed text-center">
                                <img className='about-icon' src={trustedPayment} alt="img" />
                                <h5><a href="#!">Trusted payment</a></h5>
                                <p>Doco Trip ensures a seamless travel experience with trusted payment options, giving you peace of mind from booking to journey’s end. Travel confidently, knowing your transactions are secure every step of the way.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed text-center">
                                <img className='about-icon' src={topFacility} alt="img" />
                                <h5><a href="#!">Top facility</a></h5>
                                <p>Experience the best in travel with Doco Trip's top facilities, tailored to meet every need. From premium accommodations to personalized services, we prioritize comfort and quality to make your journey truly exceptional.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_service_boxed text-center">
                                <img  src={bestDeals} className='about-icon' alt="img" />
                                <h5><a href="#!">Awesome deals</a></h5>
                                <p>Unlock incredible journeys with Doco Trip’s awesome deals! Enjoy exclusive savings on flights, hotels, and packages, making dream destinations more accessible and affordable than ever.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CounterArea />
            <FAQ />
            <Review />
            <Newshletter />
            <ScrollToTop />
            <Footer />
        </>
    )
}
export default AboutUs;