import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import avtar from '../../assets/img/Icon/avtar.png';
import Header from "../Header";
import DynamicComponent from "../../DynamicComponent";
import url from "../../Common/url";
import Common from "../../Common/Common";

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isSidebarToggled, setIsSidebarToggled] = useState(true);
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [activeComponentName, setActiveComponentName] = useState({});
    const [menu, setMenu] = useState([]);

    const UserMenuList = [
        { name: 'My Trips', link: '', isDropdown: true, submenu: [{ name: 'Package', link: url.package, icon: 'fa fa-book', ComponentName: '', isAuthRequired: false,isActive:false}, { name: 'Air Ticket', link: url.airTicket, icon: 'fa fa-book', ComponentName: '', isAuthRequired: false,isActive:false}], icon: 'fa fa-globe',isActive:false},
    ];
    const partnerMenuList = [
        { name: 'Profile', link: url.userProfile, isDropdown: false, icon: 'fa fa-book', ComponentName: 'Profile', isAuthRequired: true ,isActive:false},
        { name: 'My Trips', link: '', isDropdown: true, submenu: [{ name: 'Package', link: url.package, icon: 'fa fa-book', ComponentName: '', isAuthRequired: false ,isActive:false}, { name: 'Air Ticket', link: url.airTicket, icon: 'fa fa-book', ComponentName: '', isAuthRequired: false,isActive:false }], icon: 'fa fa-globe' ,isActive:false},
        { name: 'Wallet', link: url.wallet, isDropdown: false, icon: 'fa fa-folder', isAuthRequired: true,isActive:false },
    ];
    const simpleMenuList = [
        { name: 'My Trips', link: '', isDropdown: true, submenu: [{ name: 'Package', link: url.package, icon: 'fa fa-book', ComponentName: '', isAuthRequired: false ,isActive:false}], icon: 'fa fa-globe' ,isActive:false},
    ];

    const handleDropdownClick = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    const handleSidebarToggle = () => {
        setIsSidebarToggled(!isSidebarToggled);
    };

    const setActivePage = (menuList) => {
        const currentPath = location.pathname;
        const activeMenu = menuList.find((item) => item.link === currentPath || (item.isDropdown && item.submenu.some((sub) => sub.link === currentPath)));
        if (activeMenu) {
            console.log('ac',activeMenu)
            activeMenu.isActive=true;
            setActiveComponentName(activeMenu.isDropdown ? activeMenu.submenu.find((sub) => sub.link === currentPath) : activeMenu);
        } else {
            setActiveComponentName({});
        }
    };

    const GetMenuByRole = () => {
        const isUserAuth = Common.isUserAuthenticated();
        let selectedMenu = simpleMenuList;

        if (isUserAuth) {
            setIsUserLogin(true);
            const userData = Common.getUserLoginDetails();
            selectedMenu = userData.userTypeID === 4 ? partnerMenuList : userData.userTypeID === 2 ? UserMenuList : [];
        } else {
            setIsUserLogin(false);
        }

        setMenu(selectedMenu);
        setActivePage(selectedMenu); 
    };

    useEffect(() => {
        GetMenuByRole();
    }, [location.pathname]);

    return (
        <>
            <Header customizedHeader={1} />
            <div className={`page-wrapper ${isSidebarToggled ? "toggled" : ""}`}>
                <div id="show-sidebar" className="btn btn-sm btn-dark" onClick={handleSidebarToggle}>
                    <i className="fas fa-bars"></i>
                </div>
                <nav id="sidebar" className="sidebar-wrapper">
                    <div className="sidebar-content">
                        <div className="sidebar-brand">
                            <Link to="#">Doco Trip</Link>
                            <div id="close-sidebar" onClick={handleSidebarToggle}>
                                <i className="fas fa-times"></i>
                            </div>
                        </div>
                        <div className="sidebar-header">
                            <div className="user-pic">
                                <img className="img-responsive img-rounded" src={avtar} alt="User" />
                            </div>
                            <div className="user-info">
                                <span className="user-name">
                                    <strong>{`${Common.getUserLoginDetails().firstName ?? ''} ${Common.getUserLoginDetails().lastName ?? ''}`}</strong>
                                </span>
                                <span className="user-role">{Common.getUserLoginDetails().userType ?? ''}</span>
                            </div>
                        </div>
                        <div className="sidebar-menu">
                            <ul>
                                {menu.map((item, index) => (
                                    <li key={index} className={item.isDropdown ? "sidebar-dropdown" : ""} onClick={() => handleDropdownClick(index)}>
                                        <Link to={item.link || "#"} className={`${activeComponentName.name === item.name ? 'text-dark':''}`}>
                                            <i className={item.icon}></i> <span>{item.name}</span>
                                        </Link>
                                        {item.isDropdown && (
                                            <div className="sidebar-submenu" style={{ display: activeDropdown === index ? "block" : "none" }}>
                                                <ul>
                                                    {item.submenu.map((subItem, ind) => (
                                                        <li key={ind}>
                                                            <Link to={subItem.link}>{subItem.name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="sidebar-footer">
                        <Link to="#">
                            <i className="fa fa-bell"></i>
                            <span className="badge badge-pill badge-warning notification">3</span>
                        </Link>
                        <Link to="#">
                            <i className="fa fa-envelope"></i>
                            <span className="badge badge-pill badge-success notification">7</span>
                        </Link>
                        <Link to="#">
                            <i className="fa fa-cog"></i>
                            <span className="badge-sonar"></span>
                        </Link>
                        <Link to="#">
                            <i className="fa fa-power-off"></i>
                        </Link>
                    </div>
                </nav>
                <main className="page-content">
                    <div className="container-fluid">
                        <h2>Profile</h2>
                        <hr />
                        <DynamicComponent componentName={activeComponentName.ComponentName} />
                    </div>
                </main>
            </div>
        </>
    );
};

export default Sidebar;
