import React from "react";
import './style.css'
import CommunicationHub from "../../Common/CommunicationHub";
import url from "../../Common/url";
import { Link } from "react-router-dom";
const FAQ=()=>{

    return(
        <>
                <div className="container custom-padding">
                    <div className='row custom-box-shadow faq-container'>
                        <div className="col-12">
                            <div className="faq-title">
                                <h5>Frequently Asked Questions (FAQs)</h5>
                                <p className="faq-title-details">Here's a compilation of all the travel queries you may have. We're pretty sure the answer to your question will be here. Just in case you don't see it, please  <Link to={url.contactUs} target="_blank">contact us </Link></p>
                            </div>
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseOne"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseOne"
                                        >
                                            What services does Docotrip offer?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingOne"
                                    >
                                        <div className="accordion-body">
                                            At Docotrip, we provide a wide range of travel solutions, including:
                                            Tour packages (national and international)
                                            Corporate travel packages
                                            Umrah pilgrimage packages
                                            Air ticketing
                                            Hotel bookings
                                            Expert travel consultancy to plan your perfect trip
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseTwo"
                                        >
                                           Do you offer customized travel packages?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingTwo"
                                    >
                                        <div className="accordion-body">
                                            Yes, we specialize in creating tailor-made travel experiences! Share your preferences, and our travel consultants will craft a package that suits your needs.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseThree"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                           What makes Docotrip’s Umrah packages unique?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                    >
                                        <div className="accordion-body">
                                            Our Umrah packages are thoughtfully designed to include:
                                            Comfortable accommodations close to the holy sites
                                            Hassle-free visa processing
                                            Guided itineraries for a spiritual journey
                                            Competitive pricing for groups and individuals
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseFour"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                          Can you help with visa assistance?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                    >
                                        <div className="accordion-body">
                                            Absolutely! We assist with visa processing for many international destinations, ensuring all documentation is in order.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseFive"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                         How can I book my air tickets and hotels through Docotrip?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                    >
                                        <div className="accordion-body">
                                           Booking with Docotrip is easy:
                                            Visit our online  or visit office or contact us online.
                                            Provide your travel details.
                                            Let us handle the rest—your tickets and accommodation will be booked in no time.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseSix"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                          Do you offer corporate travel solutions?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseSix"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                    >
                                        <div className="accordion-body">
                                           Yes, we specialize in corporate travel management, offering:
                                            Tailored packages for business trips
                                            Cost-effective travel planning
                                            24/7 support for seamless travel experiences
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                         What destinations do you cover?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseSeven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                    >
                                        <div className="accordion-body">
                                           We cover a vast range of destinations, from local hotspots to international dream destinations. Whether it's a serene beach vacation, a cultural tour, or a business trip, we've got you covered!
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseEight"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                         How can I get in touch with your travel consultants?
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseEight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingThree"
                                    >
                                        <div className="accordion-body">
                                           Our friendly travel consultants are available via:<br />
                                            Contact Us: <Link to={url.contactUs} target="_blank">Visit Page</Link><br />
                                            Phone: {CommunicationHub.landLineNo}<br />
                                            Email: {CommunicationHub.supportMail}<br />
                                            Office visits <Link to={CommunicationHub.offliceLocationCoordinate} target='_blank'><small>(Location)</small></Link> for a one-on-one consultation
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default FAQ;